/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

$(async function () {

	// Calculate
	var timeout = false,
		timeoutDelay = 250;

	function calculateSizes() {
		document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight}px`);
	}

	calculateSizes();

	window.addEventListener('resize', function () {
		clearTimeout(timeout);
		timeout = setTimeout(calculateSizes, timeoutDelay);
	});

	// Responsive menu
	$('header').each(function () {
		var element = $(this),
			body = $(document.body),
			responsiveNav = $('.responsive-nav');

		var toggler = $('<div class="toggler toggler--closed"><span>Menu</span></div>').click(function () {
			body.toggleClass('open');
			responsiveNav.toggleClass('collapsed');
			toggler.toggleClass('toggler--closed');
		}).appendTo(element);
	});

	// Open sub menu on touch or in responsive menu
	$('.responsive-nav .nav .sub').each(function () {
		var element = $(this),
			a = element.find('> a');

		a.on("touchend", function (e) {
			if (!element.hasClass('open') && element.find('ul')[0] !== null) {
				element.addClass('open');
				e.preventDefault();
			}
		});
	});

	// Open social media on touch
	$('.social-media').each(async function () {
		var $this = $(this);

		$this.on('touchend', function (e) {
			$this.addClass('open');
			e.stopPropagation();
		});

		$(document).on('touchend', function () {
			$this.removeClass('open');
		});
	});

	// Slider
	$('.slider').each(async function () {
		await import('lightslider');
		var element = $(this);
		var cssClass = element.attr('class');

		element.lightSlider({
			slideMargin: 0,
			loop: true,
			auto: true,
			speed: 1000,
			pause: 3500,
			pager: false,
			controls: false,
			item: 6,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						item: 5
					}
				},
				{
					breakpoint: 768,
					settings: {
						item: 4
					}
				},
				{
					breakpoint: 640,
					settings: {
						item: 3
					}
				},
				{
					breakpoint: 480,
					settings: {
						item: 2
					}
				}
			]
		});
	});

	$('.slider--full-width').each(async function () {
		await import('lightslider');
		var element = $(this);
		var cssClass = element.attr('class');

		element.lightSlider({
			slideMargin: 32,
			loop: true,
			auto: true,
			speed: 1000,
			pause: 3500,
			pager: false,
			controls: true,
			adaptiveHeight: true,
			item: 1
		});
	});

	$('.slider--double').each(async function () {
		await import('lightslider');
		var element = $(this);
		var cssClass = element.attr('class');

		element.lightSlider({
			slideMargin: 0,
			loop: true,
			auto: true,
			speed: 1000,
			pause: 3500,
			pager: false,
			controls: true,
			item: 2,
			responsive: [
				{
					breakpoint: 640,
					settings: {
						item: 1
					}
				}
			]
		});
	});

	$('.fader').each(async function () {
		await import('lightslider');
		var element = $(this);
		var cssClass = element.attr('class');

		element.lightSlider({
			slideMargin: 32,
			mode: "fade",
			loop: true,
			auto: true,
			speed: 1000,
			pause: 4500,
			pager: true,
			controls: false,
			adaptiveHeight: true,
			item: 1
		});
	});

	// Match height
	async function matchHeights() {
		await import('jquery-match-height');

		$('.overview .item > *').matchHeight({
			property: 'min-height'
		});
	};

	if ($('.overview .item > *')[0]) {
		matchHeights();
	}

	// Lightbox
	$('#container:has([rel=lightbox])').each(async function () {
		await import('lightgallery');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Route form
	$('.form--route').each(function () {
		var element = $(this);
		var button = element.find('button');
		var destinationAddress = element.find('input[type=text]');
		var destination = button.attr('data-address');

		// Route on button click
		button.click(function (e) {
			var route = {
				saddr: destinationAddress.val(),
				daddr: destination.val()
			};

			window.open('https://maps.google.com/?' + $.param(route), '_blank');

			e.preventDefault();
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Jump to submitted form
	$('.umbraco-forms-form[id]:has(form)').each(function () {
		var umbForm = $(this), form = umbForm.find('form');
		form.attr('action', form.attr('action') + '#' + umbForm.attr('id'));
	});

	// Toasts
	if (window.toasts) {
		const toastsCookieName = 'toasts',
			toastsCookieOptions = {
				sameSite: 'strict',
				secure: true
			};

		if (window.toasts.length) {
			(async () => {
				const Toasts = (await import('./modules/toasts.js')).default;
				new Toasts(window.toasts, toastsCookieName, toastsCookieOptions);
			})();
		} else {
			// Delete cookie if there are no active toasts
			Cookies.remove(toastsCookieName, toastsCookieOptions);
		}
	}
});

document.querySelectorAll('form').forEach(el => {

	let button = el.querySelector('input[type=submit]');

	el.addEventListener('submit', function () {
		button.classList.add('is-loading');
	});
});